<script setup>
import { Link } from '@inertiajs/vue3';


const props = defineProps({
	size: {
		type: String,
		required: false,
		default: "medium",
	},
	theme: {
		type: String,
		required: false,
		default: "primary-contained",
	},
	iconPosition: {
		type: String,
		required: false,
		default: null,
	},
	text: {
		type: String,
		required: false,
		default: null,
	},
	type: {
		type: String,
		required: false,
		default: 'submit',
	},
	isLink: {
		type: Boolean,
		default: false
	},
	to: {
		type: String,
		default: null
	}
});

</script>
<template>
	<button v-if="isLink === false" v-bind="$attrs" :type="type" :class="[
		'flex text-center items-center gap-2 rounded-lg justify-center px-3 py-2 font-bold whitespace-nowrap leading-normal cursor-pointer transition-all',
		{
			
			'text-[13px] px-3 py-1 font-bold': props.size === 'small',
			'text-sm px-3 py-2.5 font-bold': props.size === 'medium',
			'text-base px-4 py-[11px] font-bold': props.size === 'large',

			'border border-transparent text-white bg-secondary-16 hover:bg-transparent hover:text-secondary-16 hover:border-secondary-16':
                    props.theme === 'inherit-contained',
                'border border-secondary text-secondary bg-transparent hover:bg-secondary hover:text-white':
                    props.theme === 'inherit-outlined',
                'border border-transparent text-secondary-16 bg-transparent hover:bg-secondary-16 hover:text-white':
                    props.theme === 'inherit-text',
                'border border-transparent text-secondary bg-secondary-12 hover:bg-secondary hover:text-secondary-16':
                    props.theme === 'inherit-soft',
                'border border-transparent text-white bg-primary hover:text-primary hover:bg-white hover:border-primary':
                    props.theme === 'primary-contained',
                'border border-primary text-primary bg-transparent hover:text-white hover:bg-primary hover:border-transparent':
                    props.theme === 'primary-outlined',
                'border border-transparent text-primary hover:bg-primary hover:text-white':
                    props.theme === 'primary-text',
                'border border-transparent text-primary bg-secondary-12 hover:text-white hover:bg-primary':
                    props.theme === 'primary-soft',
                'border border-transparent text-white bg-system hover:text-system hover:bg-white hover:border-system':
                    props.theme === 'system-contained',
                'border text-system border-system hover:border-transparent hover:bg-system hover:text-white':
                    props.theme === 'system-outlined',
                'border border-transparent text-system hover:text-white hover:bg-system':
                    props.theme === 'system-text',
                'border border-transparent text-system bg-secondary-12 hover:bg-system hover:text-white':
                    props.theme === 'system-soft',
                'border border-main text-white bg-main hover:bg-white hover:text-main':
                    props.theme === 'main-contained',
                'border border-main text-main bg-transparent hover:bg-main hover:text-white':
                    props.theme === 'main-outlined',
                'border border-transparent text-main bg-transparent hover:bg-main hover:text-white':
                    props.theme === 'main-text',
                'border border-transparent text-main bg-main-light hover:bg-main hover:text-white':
                    props.theme === 'main-soft',
			'border border-transparent text-error-dark bg-error-8 hover:bg-error-dark hover:text-white':
				props.theme === 'error-soft',
		},
		'',
	]">

		<template v-if="props.iconPosition === 'left'">
			<slot name="leftIcon" />
		</template>
		<slot />
		{{ text }}
		<template v-if="props.iconPosition === 'right'">
			<slot name="rightIcon" />
		</template>
	</button>

	<Link v-else v-bind="$attrs" :href="to" :class="[
		'items-center text-center gap-2 flex rounded-lg justify-center px-3 py-2 font-bold leading-tight cursor-pointer transition-all',
		{
			'text-[13px] px-3 py-1 font-bold  ': props.size === 'small',
			'text-sm px-3 py-2 font-bold ': props.size === 'medium',
			'text-base px-4 py-[11px] font-bold ': props.size === 'large',
			'border border-transparent text-white bg-secondary-16 hover:bg-transparent hover:text-secondary-16 hover:border-secondary-16':
                    props.theme === 'inherit-contained',
                'border border-secondary text-secondary bg-transparent hover:bg-secondary hover:text-white':
                    props.theme === 'inherit-outlined',
                'border border-transparent text-secondary-16 bg-transparent hover:bg-secondary-16 hover:text-white':
                    props.theme === 'inherit-text',
                'border border-transparent text-secondary bg-secondary-12 hover:bg-secondary hover:text-secondary-16':
                    props.theme === 'inherit-soft',
                'border border-transparent text-white bg-primary hover:text-primary hover:bg-white hover:border-primary':
                    props.theme === 'primary-contained',
                'border border-primary text-primary bg-transparent hover:text-white hover:bg-primary hover:border-transparent':
                    props.theme === 'primary-outlined',
                'border border-transparent text-primary hover:bg-primary hover:text-white':
                    props.theme === 'primary-text',
                'border border-transparent text-primary bg-secondary-12 hover:text-white hover:bg-primary':
                    props.theme === 'primary-soft',
                'border border-transparent text-white bg-system hover:text-system hover:bg-white hover:border-system':
                    props.theme === 'system-contained',
                'border text-system border-system hover:border-transparent hover:bg-system hover:text-white':
                    props.theme === 'system-outlined',
                'border border-transparent text-system hover:text-white hover:bg-system':
                    props.theme === 'system-text',
                'border border-transparent text-system bg-secondary-12 hover:bg-system hover:text-white':
                    props.theme === 'system-soft',
                'border border-main text-white bg-main hover:bg-white hover:text-main':
                    props.theme === 'main-contained',
                'border border-main text-main bg-transparent hover:bg-main hover:text-white':
                    props.theme === 'main-outlined',
                'border border-transparent text-main bg-transparent hover:bg-main hover:text-white':
                    props.theme === 'main-text',
                'border border-transparent text-main bg-main-light hover:bg-main hover:text-white':
                    props.theme === 'main-soft',
			'border border-transparent text-error-dark bg-error-8':
				props.theme === 'error-soft',
		},
		'',
	]">
	<template v-if="props.iconPosition === 'left'">
		<slot name="leftIcon" />
	</template>
	<slot />
	{{ text }}
	<template v-if="props.iconPosition === 'right'">
		<slot name="rightIcon" />
	</template>
	</Link>

</template>
